import React, { FC } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Brand } from '../Brand';
import './Header.scss';

type HeaderProps = {
  className?: string;
};

export const Header: FC<HeaderProps> = ({ className }) => {
  const blockName = 'header';
  const classMap = [blockName, className];
  return (
    <Navbar expand={'lg'} className={classMap.join(' ')} fixed='top'>
      <Container className='d-flex justify-content-center'>
        <Navbar.Brand>
          <Link to='/'>
            <Brand className={`${blockName}_brand`} />
          </Link>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

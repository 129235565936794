import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const { REACT_APP_SENTRY_DSN: dsn, REACT_APP_ENVIRONMENT: environment } = process.env;

Sentry.init({
  dsn,
  environment,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';

import { StartLayout } from './layouts/StartLayout';
import { ContentLayout } from './layouts/ContentLayout';
import { Start } from './screens/Start';
// import { About } from './screens/About';
import { TsCs } from './screens/TsCs';
import { Cookies } from './screens/Cookies';
import { PrivacyPolicy } from './screens/PrivacyPolicy';
import { NotFoundPage } from './screens/NotFoundPage';
import { RouterErrorBoundary } from './components/RouterErrorBoundary';

export const getRoutes = () =>
  createRoutesFromElements(
    <Route errorElement={<RouterErrorBoundary />}>
      <Route path='/' element={<StartLayout />}>
        <Route index element={<Start />} />
      </Route>
      {/* <Route path='/about' element={<ContentLayout />}>
        <Route index element={<About />} />
      </Route> */}
      <Route path='/terms-and-conditions' element={<ContentLayout />}>
        <Route index element={<TsCs />} />
      </Route>
      <Route path='/cookies' element={<ContentLayout />}>
        <Route index element={<Cookies />} />
      </Route>
      <Route path='/privacy-policy' element={<ContentLayout />}>
        <Route index element={<PrivacyPolicy />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  );
